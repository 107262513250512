const cssPrint = `<style>
  .page-break {
    page-break-after: always;
  }
  .m-t {
    margin-top: 15px;
  }
  .d-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media print {
    body {
      margin-top: 0px;
    }
  
   
  }


  

</style>`
export default cssPrint
