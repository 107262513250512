<template>
  <div>
    <b-col cols="12">
      <b-form-group
        label="Large"
        label-for="largeInput"
      >
        <b-form-input
          id="largeInput"
          v-model="dob"
          size="lg"
          style="width:25%"
          placeholder="Large Input"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group
        label="Large"
        label-for="largeInput"
      >
        <b-form-input
          id="largeInput"
          v-model="dobeng"
          size="lg"
          style="width:25%"
          placeholder="Large Input"
        />
      </b-form-group>
    </b-col>
    <b-form-select
      v-model="selected"
      :options="offset1"
      size="sm"
      style="width:25%"
      @input="addSticker"
    />
    <br>
    <br>
    <div>
      <b-button
        class="mr-2"
        variant="danger"
        @click="generate"
      >
        print-sticker
      </b-button>
      <b-button
        variant="info"
        @click="addSticker"
      >
        add
      </b-button>
      <br>
      <br>
      <div id="print-sticker" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import cssPrint from './css'

$.fn.extend({
  print() {
    // var tempTitle = document.title
    // $("title", "head").text(title).trigger('change')
    const frameName = 'printIframe'
    let doc = window.frames[frameName]
    if (!doc) {
      $('<iframe>').hide().attr('name', frameName).appendTo(document.body)
      doc = window.frames[frameName]
    }
    doc.document.body.innerHTML = cssPrint
    doc.document.body.innerHTML += this.html()
    // doc.document.close();
    doc.window.print()
    // $("title", "head").text(tempTitle).trigger('change')
    return this
  },
})

export default {
  data() {
    return {
      selected: '',
      dob: 0,
      dobeng: 0,
      offsetall: ['0',
        '1280',
        '2560',
        '3840',
        '5120',
        '6400',
        '7680',
        '8960',
        '10240',
        '11520',
        '12800',
        '14080',
        '15360',
        '16640',
        '17920',
        '19200',
        '20480'],
      offset3: ['0',
        '12800',
        '13440',
        '14080',
        '14720',
        '15360',
        '16000',
        '16640',
        '17280',
        '17920',
        '18560',
        '19200',
        '19840',
        '20480',
        '21120',
        '21760',
        '22400',
        '23040',
        '23680',
        '24320'],
      offset4: ['0',
        '101',
        '640',
        '1280',
        '1920',
        '2560',
        '3200',
        '3840',
        '4480',
        '5120',
        '5760',
        '6400',
        '7040',
        '7680',
        '8320',
        '8960',
        '9600',
        '10240',
        '10880',
        '11520',
        '12160',
        '12800',
        '13440',
        '14080',
        '14720',
        '15360',
        '16000',
        '16640',
        '17280',
        '17920',
        '18560',
        '19200',
        '19840',
        '20480',
        '21120',
        '21760',
        '22400',
        '23040',
        '23680',
        '24320'],
      offset1: ['0',
        '1280',
        '2560',
        '3840',
        '5120',
        '6400',
        '7680',
        '8960',
        '10240',
        '11520',
        '12800',
        '14080',
        '15360',
        '16640',
        '17920',
        '19200',
        '20480',
        '21760',
        '23040',
        '24320',
        '25600',
        '26880',
        '28160'],
      offset2: ['8500',
        '21760',
        '23040',
        '24320',
        '25600',
        '26880',
        '28160',
        '29440',
        '30720',
        '32000',
        '33280',
        '34560',
        '35840',
        '37120',
        '38400',
        '39680',
        '40960',
        '42240',
        '43520',
        '44800',
        '46080',
        '47360',
        '48640',
        '49920',
        '51200']
      ,
    }
  },
  watch: {
    dob() {
      this.dobeng = this.dob - 543
    },
  },
  methods: {
    generate() {
      $('#print-sticker').print()
    },
    addSticker() {
      // let n_loop = api stikcer
      // const nLoop = 100
      this.$http({
        url: `https://localhost:5001/api/Vaccination/getLotALL/${this.selected}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.getters.isLoggedIn}`,
        },
      }).then(({ data }) => {
        // console.log(data)
        for (let i = 0; i < data.length; i += 1) {
          $('#print-sticker').append(
            `<div class="page-break m-t d-flex">
              <img src="data:image/png;base64, ${data[i].base64}">
              <span>${data[i].lotSriphat}</span>
            </div>`,
          )
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .content-hide {
    display: none;
  }
  .page-break {
     page-break-after: always;
  }
</style>
